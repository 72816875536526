<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style="width: 260px;">
    <ul class="nav nav-pills flex-column mb-auto">

      <li class="nav-item">
        <router-link class="nav-link text-white" to="/profile" :exact="false">
          <i class="icon bi-person-circle pe-none me-2"></i>
          Profile
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link text-white" to="/tools" :exact="false">
          <i class="icon bi-tools pe-none me-2"></i>
          Tools
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link text-white" to="/keys" :exact="false">
          <i class="icon bi-key pe-none me-2"></i>
          Keys
        </router-link>
      </li>

      <li class="nav-item">
        <a href="https://docs.quilt.gg/" class="nav-link text-white disabled">
          <i class="icon bi-book pe-none me-2"></i>
          Docs
        </a>
      </li>

    </ul>
    <hr>
    <div class="dropdown">
      <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
        data-bs-toggle="dropdown" aria-expanded="false">
        <img :src="userImgUrl" alt="" width="32"
          height="32" class="rounded-circle me-2">
        <strong>{{ userName }}</strong>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
        <!--<li><a class="dropdown-item disabled" href="#">New project...</a></li>-->
        <!--<li><a class="dropdown-item disabled" href="#">Settings</a></li>-->
        <!--<li><a class="dropdown-item disabled" href="#">Profile</a></li>-->
        <!--
        <li>
          <hr class="dropdown-divider">
        </li>
        -->
        <li><a class="dropdown-item" @click="logOut">Sign out</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { googleLogout } from 'vue3-google-login'

export default {
  computed: {
    userImgUrl() {
      return JSON.parse(window.localStorage.getItem("user")).picture || "images/user-circle-solid-36.png"
    },
    userName() {
      return JSON.parse(window.localStorage.getItem("user")).name || "{username}"
    }
  },
  methods: {
    logOut() {
      googleLogout()
      window.localStorage.removeItem('user')
      this.$router.push('/login')
    }
  },
};
</script>

<style lang="scss" scoped>

</style>