import { createApp } from 'vue'
import AsyncComputed from "vue-async-computed"
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import '@/assets/styles/app.scss'
import * as bootstrap from 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css"

const app = createApp(App)

app.use(store)
app.use(router)
app.use(vue3GoogleLogin, {
  clientId: '879271311422-lkl22sjev9fa182vk8mmbgd32lig6ep1.apps.googleusercontent.com'
})
app.use(AsyncComputed)

app.mount('#app')
